<template>
    <div data-component="AboutApplications">
        <div class="about-applications">
            <div class="wrapper">
                <div class="about-applications__inner">
                    <div class="information">
                        <h2>{{ $ml.get("information_is_always_there_even_in_the_absence_of_the_internet") }}</h2>

                        <div class="application_links">
                            <a v-for="(application, index) in applications"
                               :href="application.currentLink"
                               :key="`application_link__${index}`"
                                target="_blank"
                            >
                                <img :src="application.image" :alt="application.alt">
                            </a>
                        </div>
                    </div>

                    <div class="images">
                        <img v-for="(phone, index) in phones"
                             class="phone"
                             :class="phone.customClass"
                             :src="phone.img"
                             :key="`phone-image__${index}`"
                             alt="Phone"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//images
import DownloadOnTheAppStoreImage from "@/assets/images/download-on-the-appstore.png";
import GetItOnGooglePlayImage from "@/assets/images/get-it-on-google-play.png";
import PhoneFirstImage from "@/assets/images/phone_1.webp";
import PhoneSecondImage from "@/assets/images/phone_2.webp";
import PhoneThirdImage from "@/assets/images/phone_3.webp";

export default {
    name: "AboutApplications",

    data() {
        return {
            applications: [
              {
                  alt: "AppStore",
                  image: DownloadOnTheAppStoreImage,
                  currentLink: "https://apps.apple.com/ua/app/kadastr-ua/id1495599039?l=ru",
              },
              {
                  alt: "GooglePlay",
                  image: GetItOnGooglePlayImage,
                  currentLink: "https://play.google.com/store/apps/details?id=farm.soft.cadastre&hl=uk",
              },
            ],

            phones: [
                {
                    img: PhoneFirstImage,
                    customClass: "phone_1",
                },
                {
                    img: PhoneSecondImage,
                    customClass: "phone_2",
                },
                {
                    img: PhoneThirdImage,
                    customClass: "phone_3",
                },
            ]

        };
    },
}
</script>

<template>
    <div class="how-it-works__list__item" data-component="HowItWorksItem">
        <div class="image">
            <img :src="imgSrc" alt="Image">
        </div>

        <p class="description">{{ $ml.get(description) }}</p>
    </div>
</template>

<script>
export default {
    name: "HowItWorksItem",
    props: {
        imgSrc: {
            type: String,
            require: true,
        },
        description: {
            type: String,
            require: true,
        }
    }
}
</script>

<style scoped>

</style>

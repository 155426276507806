<template>
    <div class="how-it-works" data-component="HowItWorks" id="HowItWorks">
        <div class="wrapper">
            <h1 class="title">{{ $ml.get("how_it_works") }}</h1>

            <div class="how-it-works__list">
                <how-it-works-item
                    v-for="(item, index) in items"
                    :imgSrc="item.img"
                    :description="item.description"
                    :key="`how-it-works-item__${index}`"
                />
            </div>
        </div>
    </div>
</template>

<script>
// images
import PersonalAreaImage from "@/assets/icons/personal-area.svg";
import ComboBoxImage from "@/assets/icons/combo-box.svg";
import SearchForInformationImage from "@/assets/icons/search-for-information.svg";
import UseAnalyticsImage from "@/assets/icons/use-analytics.svg";
import SingleAccountImage from "@/assets/icons/single-account.svg";

import HowItWorksItem from "@/components/HowItWorksItem";

export default {
    name: "HowItWorks",
    data() {
      return {
          items: [
              {
                  img: PersonalAreaImage,
                  description: "how_it_works_1_description",
              },
              {
                  img: ComboBoxImage,
                  description: "how_it_works_2_description",
              },
              {
                  img: SearchForInformationImage,
                  description: "how_it_works_3_description",
              },
              {
                  img: UseAnalyticsImage,
                  description: "how_it_works_4_description",
              },
              {
                  img: SingleAccountImage,
                  description: "how_it_works_5_description",
              },
          ]
      };
    },
    components: {
        HowItWorksItem
    }
}
</script>
